import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Navigatebar() {
  return (
    <Navbar
      expand="lg"
      style={{ backgroundColor: "#FFF8F5", minWidth: "200px" }}
    >
      <Container fluid>
        {/* Brand Section */}
        <div className="d-flex justify-content-start ms-3">
          <Navbar.Brand href="#" style={{ color: "#BB1314" }}>
            Flicker App
          </Navbar.Brand>
        </div>

        {/* Collapsible Toggle */}
        <Navbar.Toggle aria-controls="navbarScroll" />

        {/* Navbar Links Section */}
        <Navbar.Collapse id="navbarScroll">
          <div className="d-flex justify-content-center w-100 flex-nowrap">
            <Nav className="mx-auto">
              <Nav.Link href="#about" className="pe-3">
                About Us
              </Nav.Link>
              <Nav.Link href="#genre" className="pe-3">
                Popular Genre
              </Nav.Link>
              <Nav.Link href="#testimonials" className="pe-3">
                Testimonials
              </Nav.Link>
              <Nav.Link href="#collections" className="pe-3">
                Top Collections
              </Nav.Link>
              <Nav.Link href="#pricing" className="pe-3">
                Pricing
              </Nav.Link>
              <Nav.Link href="#faq" className="pe-3">
                FAQ
              </Nav.Link>
            </Nav>
          </div>

          {/* Buttons Section */}
          <div className="d-none d-lg-flex justify-content-end me-3">
            {/* This will keep buttons aligned to the right on large screens */}
            <Button
              variant="outline-success"
              className="me-2"
              style={{
                minWidth: "100px",
                backgroundColor: "#BB1314",
                border: "none",
                color: "white",
              }}
            >
              Log In
            </Button>
            <Button
              variant="outline-success"
              style={{
                minWidth: "100px",
                borderColor: "#BB1314",
                color: "#BB1314",
                backgroundColor: "white",
              }}
            >
              Sign Up
            </Button>
          </div>

          {/* Centered buttons for small screens */}
          <div className="d-flex d-lg-none justify-content-center w-100 mt-3">
            <Button
              variant="outline-success"
              className="me-2"
              style={{
                minWidth: "100px",
                backgroundColor: "#BB1314",
                border: "none",
                color: "white",
              }}
            >
              Log In
            </Button>
            <Button
              variant="outline-success"
              style={{
                minWidth: "100px",
                borderColor: "#BB1314",
                color: "#BB1314",
                backgroundColor: "white",
              }}
            >
              Sign Up
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigatebar;
