import React from "react";
import { BiBrightness } from "react-icons/bi";

function Container8() {
  return (
    <footer
      className="footer-section py-5 text-white"
      style={{
        background: "linear-gradient(to right, #BB1314, #ED553B)",
        opacity: "0.75",
        borderTopLeftRadius: "20px", // Rounded top left corner
        borderTopRightRadius: "20px", // Rounded top right corner
      }}
    >
      <div className="container">
        <div className="row">
          {/* Flicker description */}
          <div className="col-md-4">
            <h5>Flicker App</h5>
            <p>
              Flicker App is a platform for busy readers, offering curated book
              summaries and personalized recommendations. Whether you prefer to
              read or listen, we help you learn and explore faster.
            </p>
            <div className="d-flex gap-2">
              <a className="text-white text-decoration-none">
                <i className="fab fa-facebook"></i>
              </a>
              <a className="text-white text-decoration-none">
                <i className="fab fa-linkedin"></i>
              </a>
              <a className="text-white text-decoration-none">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="text-white text-decoration-none">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          {/* Quick links */}
          <div className="col-md-2">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a className="text-white text-decoration-none">Home</a>
              </li>
              <li>
                <a className="text-white text-decoration-none">Features</a>
              </li>
              <li>
                <a className="text-white text-decoration-none">Pricing</a>
              </li>
              <li>
                <a className="text-white text-decoration-none">Contact</a>
              </li>
            </ul>
          </div>

          {/* Important links */}
          <div className="col-md-2">
            <h5>Important Links</h5>
            <ul className="list-unstyled">
              <li>
                <a className="text-white text-decoration-none">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="text-white text-decoration-none">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a className="text-white text-decoration-none">Support</a>
              </li>
            </ul>
          </div>

          {/* Subscribe section */}
          <div className="col-md-4">
            <h5>Subscribe</h5>
            <p>
              Stay updated with the latest AI-curated content and special
              offers.
            </p>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
              />
              <button className="btn btn-light">→</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer bottom */}
      <div className="text-center mt-4">
        <p className="mb-0">© 2024 Flicker App. All Rights Reserved.</p>
        <div>
          <a className="text-white text-decoration-none me-2">Privacy</a> |
          <a className="text-white text-decoration-none ms-2">
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Container8;
