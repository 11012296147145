import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Container2() {
    return (
        <div style={{ backgroundColor: '#FFF8F5', padding: '20px' }}>
            <section id='about' className="ms-4 me-4 mt-4" style={{ backgroundColor: '#FFF8F5' }}>
                <Container fluid className="mt-5 px-4">
                    <h2 className="text-center">How Our App Works</h2>
                    <p className="text-center">
                        We've reimagined how you consume books. Whether you're a busy professional or a curious
                        learner, our app delivers personalized book summaries in a way that suits your lifestyle.
                    </p>

                    {/* Card Deck Section */}
                    <Row className="g-4 mt-4 justify-content-center">
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card className="shadow h-100" style={{
                                backgroundImage: 'linear-gradient(to top , #AEFBFA, #E2F9F9)',
                                textAlign: 'center',
                                paddingBottom: '10px',
                                border: 'none'
                            }}>
                                <div style={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    height: '200px',
                                }}>
                                    <Card.Img
                                        src="images/genre2.avif"
                                        alt="Card 1"
                                        style={{
                                            borderRadius: '15px',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title>Pick Your Genres</Card.Title>
                                    <Card.Text>Choose from a wide variety of genres for personalized recommendations.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card className="shadow h-100" style={{
                                backgroundImage: 'linear-gradient(to top , #CCB8F1, #ECDDFA)',
                                textAlign: 'center',
                                paddingBottom: '10px',
                                border: 'none'
                            }}>
                                <div style={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    height: '200px',
                                }}>
                                    <Card.Img
                                        src="images/read.jpg"
                                        alt="Card 2"
                                        style={{
                                            borderRadius: '15px',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title>Read Or Listen</Card.Title>
                                    <Card.Text>Get summaries in both text and audio formats. Switch between them effortlessly.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card className="shadow h-100" style={{
                                backgroundImage: 'linear-gradient(to top , #FFF4A2, #FCF4D3)',
                                textAlign: 'center',
                                paddingBottom: '10px',
                                border: 'none'
                            }}>
                                <div style={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    height: '200px',
                                }}>
                                    <Card.Img
                                        src="images/search.jpg"
                                        alt="Card 3"
                                        style={{
                                            borderRadius: '15px',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title>AI Search</Card.Title>
                                    <Card.Text>Leverage the power of AI to find the best summaries for you.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card className="shadow h-100" style={{
                                backgroundImage: 'linear-gradient(to top , #E0FBB9, #ECFAD5)',
                                textAlign: 'center',
                                paddingBottom: '10px',
                                border: 'none'
                            }}>
                                <div style={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    height: '200px',
                                }}>
                                    <Card.Img
                                        src="images/engagement.jpg"
                                        alt="Card 4"
                                        style={{
                                            borderRadius: '15px',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title>Stay Engaged</Card.Title>
                                    <Card.Text>Track your progress, save favorites, and explore new reads daily.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default Container2;
