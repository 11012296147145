import React from "react";
import "../genre.css";

function Container3() {
  const genres = [
    { title: "Self Help", image: "images/self-help.jpeg" },
    { title: "Business", image: "images/business.jpg" },
    { title: "Science & Tech", image: "images/science-tech.jpeg" },
    { title: "Parenting", image: "images/parenting.jpg" },
    { title: "Health & Wellness", image: "images/health-wellness.jpg" },
  ];

  return (
    <div className="wrapper">
      <section id="genre" className="popular-genres">
        <div className="container text-center">
          <h2 className="section-title">Popular Genres</h2>
          <p className="section-description">
            Discover summaries across popular genres—whether you're into
            self-improvement, business strategies, the latest tech trends, or
            wellness tips. There's something here for every curious mind!
          </p>
          <div className="genres-grid">
            {genres.map((genre, index) => (
              <div className="genre-card" key={index}>
                <img
                  src={genre.image}
                  alt={genre.title}
                  className="genre-image"
                />
                <div className="genre-title">{genre.title}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Container3;
