import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import check and cross icons

function Container6() {
  const [isYearly, setIsYearly] = useState(false); // State to toggle between monthly and yearly plans

  const plans = [
    {
      title: "Free Plan",
      price: isYearly ? "$0" : "$0",
      duration: isYearly ? " /year" : " /month",
      description: "Get started with basic features available for free.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Access to 5 Summaries per Month", available: true },
        { text: "No Offline Access", available: false },
        { text: "Custom Recommendations", available: false },
        { text: "Priority Support", available: false },
      ],
      backgroundColor: "#f7fff2",
      buttonColor: "#5edc11",
      borderColor: "#5edc11",
    },
    {
      title: "Basic Plan",
      price: isYearly ? "$250" : "$25",
      duration: isYearly ? " /year" : " /month",
      description: "Unlock essential features for regular readers.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Unlimited Summaries", available: true },
        { text: "Offline Access", available: true },
        { text: "Custom Recommendations", available: false },
        { text: "Priority Support", available: false },
      ],
      backgroundColor: "#f7faff",
      buttonColor: "linear-gradient(to right, #0063ff, #64cfff)",
      borderColor: "#0063ff",
    },
    {
      title: "Standard Plan",
      price: isYearly ? "$350" : "$35",
      duration: isYearly ? " /year" : " /month",
      description: "Enjoy premium features with additional perks.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Unlimited Summaries", available: true },
        { text: "Offline Access", available: true },
        { text: "Custom Recommendations", available: true },
        { text: "Priority Support", available: true },
      ],
      backgroundColor: "#ffe5ce",
      buttonColor: "#ff7222",
      borderColor: "#ff7222",
    },
  ];

  return (
    <div className="wrapper">
      <section
        id="pricing"
        className="choose-plan-section py-5"
        style={{ backgroundColor: "#FFF8F5", margin: "0 15px" }}
      >
        <div className="text-center mb-4">
          <h2 className="fw-bold">Choose Your Plan</h2>
          <p className="text-muted">
            Explore our flexible plans designed to fit your reading habits and
            preferences. Whether you're looking for a free option or premium
            features, we have the right plan to meet your needs. Choose between
            monthly and yearly subscriptions for maximum convenience.
          </p>
          <div className="d-flex justify-content-center gap-3 mb-4">
            <Button
              variant={isYearly ? "outline-primary" : "primary"}
              className="rounded-pill"
              onClick={() => setIsYearly(false)}
              style={{
                background: isYearly
                  ? ""
                  : "linear-gradient(to right, #0063ff, #64cfff) ",
              }}
            >
              Monthly
            </Button>
            <Button
              variant={isYearly ? "primary" : "outline-primary"}
              className="rounded-pill"
              onClick={() => setIsYearly(true)}
              style={{
                background: isYearly
                  ? "linear-gradient(to right, #0063ff, #64cfff)"
                  : "",
              }}
            >
              Yearly
            </Button>
          </div>
        </div>

        <Row
          className="g-1"
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          {plans.map((plan, index) => (
            <Col xs={12} md={4} key={index} style={{ padding: "0.5rem" }}>
              <Card
                className="text-center shadow-sm h-100"
                style={{
                  backgroundColor: plan.backgroundColor,
                  maxWidth: "320px",
                  margin: "0 auto",
                  borderColor: plan.borderColor,
                }}
              >
                <Card.Body>
                  <Card.Title className="fw-bold">{plan.title}</Card.Title>
                  <Card.Text style={{ fontSize: "2rem" }}>
                    {plan.price}
                    <sup style={{ color: "lightgrey", fontSize: "0.6em" }}>
                      {plan.duration}
                    </sup>
                  </Card.Text>
                  <Card.Text>{plan.description}</Card.Text>
                  <ul className="list-unstyled mb-4 text-start">
                    {plan.features.map((feature, idx) => (
                      <li
                        key={idx}
                        className="text-muted d-flex align-items-center"
                      >
                        {feature.available ? (
                          <FaCheckCircle
                            className="text-success"
                            style={{ marginRight: "5px" }}
                          />
                        ) : (
                          <FaTimesCircle
                            className="text-danger"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        <span>{feature.text}</span>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className="w-100"
                    style={{
                      background: plan.buttonColor,
                      color: "white",
                      border: "none",
                    }}
                  >
                    Choose Plan
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </div>
  );
}

export default Container6;
