import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigatebar from './components/Navigatebar';
import Container1 from './components/Container1';
import Container2 from './components/Container2';
import Container3 from './components/Container3';
import Container4 from './components/Container4';
import Container5 from './components/Container5';
import Container6 from './components/Container6';
import Container7 from './components/Container7';
import Container8 from './components/Container8';

function App() {
  return (
    <div className="App">
      <Navigatebar/>
      <Container1/>
      <Container2/>
      <Container3/>
      <Container4/>
      <Container5/>
      <Container6/>
      <Container7/>
      <Container8/>
    </div>
  );
}

export default App;
